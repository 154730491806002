.topnav {
    overflow: hidden;
    background-color: #333;
  }
  .linkButton{
    float: right;
  }
  .topnav a:first-child{
      margin-left: 100px;
  }
  .topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .topnav a.active {
    background-color: #4CAF50;
    color: white;
  }

  .topnav .logo{
      float: Left;
      
  }
.logo img{
  width: 80px;
  height: 50px;
}
.navContainer{
  width: 80%;
  margin: auto;
}
.logo a:hover,.logo a{
  display: inline-block;
  color: #f2f2f2;
  font-size: 30px;
  padding: 0px;
  text-decoration: none;
  background-color: unset;
  margin-right: 5px;
}