
.Container{
  display: flex;
  width: 90%;
  margin: 50px auto;
}
.AllPlayerSection{
  width: 65%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.NewTeamSection{
  width: 35%;
  margin-top: 30px;
}