.Player{
    border-radius: 10px;
    margin: 30px;
    box-shadow: 5px 5px 10px rgb(77, 80, 87);
}
.card{
    border: none !important;
}

.Player img{
    width: 100%;
    height: 250px;
}

 svg{
    margin-right: 10px;
}
.card-title h5{
    font-size: 25px;
    font-weight: 600;
    display: inline-block;
}
.card button{
    margin-top: 10px;
}